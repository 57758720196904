<template>
    <b-form-input type="number" :class="field.class" :style="field.style"
                  :disabled="state.disabled" :autofocus="field.autofocus"
                  :min="field.min" :max="field.max" :step="field.step" size="sm"
                  v-model="data[field.name]"
                  v-on:input="$emit('input',data)"
                  v-on:change="onChange"
                  onClick="this.select();"/>
</template>

<script>
export default {
    name: "NumericField",
    props: ['field', 'data', 'state'],
    methods: {
        onChange() {
            if (this.field.forwardchangeevent) {
                this.$emit('forward-event', {
                    event: this.field.forwardchangeevent,
                    data: this.data,
                });
            }
        },
    }
}
</script>

<style scoped>
</style>