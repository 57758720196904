<template>
    <ckeditor :class="{'ck_hide_toolbar': this.hidetoolbar || this.field.hidetoolbar, 'ck_transparent': this.transparent || this.field.transparent}"
              :editor="editor" :config="config" :disabled="disabled"
              v-model="editorData"
              v-on:input="onInput"/>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "RichTextField",
    props: {
        field: {type: Object, default: null},
        data: {type: Object, default: null},
        state: {type: Object, default: () => ({})},
        readonly: {type: Boolean, default: false},
        hidetoolbar: {type: Boolean, default: false},
        transparent: {type: Boolean, default: false},
    },
    components: {
        ckeditor: CKEditor.component
    },
    data() {
        return {
            editor: ClassicEditor,
            config: {
                toolbar: {
                    items: ['undo', 'redo', '|', 'heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'outdent', 'indent'],
                },
                removePlugins: [
                    'CKFinder', 'CKFinderUploadAdapter', 'Table', 'TableToolbar',
                    'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'EasyImage', 'ImageUpload',
                    'MediaEmbed',
                ]
            },
            disabled: this.readonly || this.state.disabled,
            editorData: this.data[this.field.name] ? this.data[this.field.name] : '',
        };
    },
    created() {
        this.config = {
            removePlugins: [
                'CKFinder', 'CKFinderUploadAdapter', 'Table', 'TableToolbar',
                'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'EasyImage', 'ImageUpload',
                'MediaEmbed',
            ]
        };
        if (this.hidetoolbar || this.field.hidetoolbar) {
            this.config.toolbar = {
                items: [],
            };
        } else {
            this.config.toolbar = {
                items: ['undo', 'redo', '|', 'heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'outdent', 'indent'],
            };
        }
    },
    methods: {
        onInput() {
            this.data[this.field.name] = this.editorData ? this.editorData : null;
            this.$emit('input');
        }
    }
}
</script>

<style>
.ck_transparent + .ck .ck-editor__main > .ck-editor__editable.ck-read-only {
    background: transparent;
    border: none;
}

.ck_hide_toolbar + .ck .ck-editor__top .ck-toolbar {
    background: transparent;
    border: none;
}
</style>
